//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

import { events } from './events'

const defaultValues = () => ({
  text: 'Are you sure?',
  confirmBtnText: 'Подтвердить',
  rejectBtnText: 'Отмена',
  textStyleType: '',
})

const mergeOptins = (oldOption, newOptions) => {
  if (!newOptions) return { ...oldOption }
  return { ...oldOption, ...newOptions }
}

export default defineComponent({
  data() {
    return {
      visible: false,
      sendResponse: null,
      modalOptions: defaultValues(),
    }
  },
  mounted() {
    events.$on('show', ({ resolver, params }) => {
      this.visible = true
      this.modalOptions = mergeOptins(defaultValues(), params)

      this.sendResponse = (response) => {
        this.visible = false
        resolver(response)
      }
    })
  },
})
