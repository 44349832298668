import { render, staticRenderFns } from "./AppConfirmModal.vue?vue&type=template&id=aa23da18&scoped=true&"
import script from "./AppConfirmModal.vue?vue&type=script&lang=js&"
export * from "./AppConfirmModal.vue?vue&type=script&lang=js&"
import style0 from "./AppConfirmModal.vue?vue&type=style&index=0&id=aa23da18&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa23da18",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RostBtn: require('/home/ilya/app/rost_web_frontend/components/rost/btn.vue').default,RostDialog: require('/home/ilya/app/rost_web_frontend/components/rost/dialog.vue').default})
