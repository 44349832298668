export const getStatusUI = (status) => {
  const statusToUI = {
    new: {
      color: '#EDFEFF',
      textColor: '#3AAEBE',
      borderColor: '#E4F9FA',
    },
    accepted: {
      color: '#E8FAF0',
      textColor: '#39C3A2',
      borderColor: '#E0F2E8',
    },
    rejected: {
      color: '#EDF1F9',
      textColor: '#9AA0B3',
      borderColor: '#E7ECF4',
    },
    closed: {
      color: '#EDF1F9',
      textColor: '#9AA0B3',
      borderColor: '#E7ECF4',
    },
  }

  if (statusToUI[status]) return statusToUI[status]
  return {
    color: '#EDF1F9',
    textColor: '#9AA0B3',
    borderColor: '#E7ECF4',
  }
}
