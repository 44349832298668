
import HeaderProfile from '../components/HeaderProfile.vue'
export default {
  components: {
    HeaderProfile,
  },
  data() {
    const LSMenuState = localStorage.getItem('MENU_STATE')
    const isMenuOpen = LSMenuState === null ? true : JSON.parse(LSMenuState)
    return {}
  },
}
