import { normalizeToTableCustomer } from './TableCustomer'
import { fromStampToDate, userGenderBirthDate } from '@/utils/normalize-helpers'
import { formatThousandSeparator } from '@/utils'

export const normalizeToSingleCustomer = (serverCustomer) => {
  return {
    ...normalizeToTableCustomer(serverCustomer),
    ...userGenderBirthDate(serverCustomer),

    registrationDate: fromStampToDate(serverCustomer.registration_date),
    email: serverCustomer.contact.email,

    sumOfOrders: serverCustomer.sum_of_orders
      ? formatThousandSeparator(serverCustomer.sum_of_orders) + '₽'
      : '-',
    avgFeedbacks: serverCustomer.avg_feedbacks || '-',
    complimentsCount: serverCustomer.compliments_count,
    home: {
      ...serverCustomer.home,
    },

    loyalCardRegistration: serverCustomer.loyal_card_registration
      ? fromStampToDate(serverCustomer.loyal_card_registration)
      : '-',

    loyalCardId: serverCustomer?.card?.id,

    loyalCardBarcode: serverCustomer.loyal_card_barcode,
    loyalCardNumber: serverCustomer.card?.number || '-',
    isReceiveChequeEmails: serverCustomer.is_receive_cheque_emails,

    tags: serverCustomer.tags ? serverCustomer.tags : [],
  }
}
