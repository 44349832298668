import { render, staticRenderFns } from "./default.vue?vue&type=template&id=75b70ab8&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=75b70ab8&lang=scss&scoped=true&"
import style1 from "./default.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75b70ab8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RostIcon: require('/home/ilya/app/rost_web_frontend/components/rost/icon.vue').default,RostBtn: require('/home/ilya/app/rost_web_frontend/components/rost/btn.vue').default,AppScrollToTopBtn: require('/home/ilya/app/rost_web_frontend/components/AppScrollToTopBtn.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VBottomNavigation,VCol,VContainer,VMain,VNavigationDrawer,VRow,VSpacer})
