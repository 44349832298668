import { normalizeToTableStories } from './TableStories'
const staticUrl = process.env.baseURLStatic

export const normalizeToSingleStory = (serverStory) => {
  return {
    ...serverStory,
    ...normalizeToTableStories(serverStory),
    slides: serverStory.slides.map((el) => ({
      prev:
        el.content.type === 'video'
          ? `${staticUrl}/${el.content.location}`
          : `${staticUrl}/resize/512x512/${el.content.location}`,
      id: el.id,
      type: el.content.type,
    })),
  }
}
