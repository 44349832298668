//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    const currentRole = this.$auth.user.roles.find((el) => el.is_selected) ||
      this.$auth.user.roles[0] || { code: 'user', name: 'Пользователь' }
    const currentRoleCode = currentRole.code
    return {
      changeRoleDialog: false,
      currentRole: currentRoleCode,
      selectedRole: currentRoleCode,
    }
  },

  methods: {
    async changeRole() {
      const refreshToken =
        this.$auth.strategy.refreshToken.$storage._state['_refresh_token.local']

      const res = await this.$axios.$post(
        `${this.$config.baseURLSSO}/oauth/token/`,
        {
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
          role: this.selectedRole,
        }
      )
      await this.$auth.setUserToken(res.access_token, res.refresh_token)
    },
    async onSaveSelectedRole() {
      if (this.currentRole === this.selectedRole) {
        this.changeRoleDialog = false
        return
      }

      await this.$appFetch(() => this.changeRole())
      if (!this.$appFetchState.error) {
        location.reload()
      }
    },
  },
}
