import * as dayjs from 'dayjs'
import { normalizeToTableOrder } from './TableOrder'
import { normalizeUser } from '@/utils/normalize-helpers'

export const normalizeToSingleOrder = (serverOrder) => {
  const { header } = serverOrder

  const history = serverOrder.state_history || []
  return {
    ...normalizeToTableOrder(header),
    collector: header.collector ? normalizeUser(header.collector) : null,
    courier: header.courier ? normalizeUser(header.courier) : null,
    products: serverOrder.products,
    services: serverOrder.services,
    stateHistory: history.map((el) => {
      return {
        ...el,
        ts: dayjs(el.ts * 1000).format('hh:mm DD MMM'),
      }
    }),
  }
}
