import * as dayjs from 'dayjs'
import { formatThousandSeparator } from '@/utils'

import { normalizeUser } from '@/utils/normalize-helpers'
export const normalizeToTableAccounting = (serverAccounting) => {
  const total = serverAccounting.total
  return {
    ...serverAccounting,
    ...normalizeUser(serverAccounting.employee),
    pay_period: serverAccounting.period.period
      .map((el) => dayjs(el).format('D MMM YYYY'))
      .join(' - '),
    typeLabel: serverAccounting.type.label,
    total: total ? formatThousandSeparator(total) : total,

    // // name: `${serverEmployee.personal.first_name} ${serverEmployee.personal.last_name}`,
    // // phone: '+' + serverEmployee.contact.phone,
    // specialization: serverEmployee.specialization.label,
    // specializationValue: serverEmployee.specialization.value,
    // qualification: serverEmployee.qualification.label,
    // // img: require('@/assets/images/empty-avatart.png'),
  }
}
