import dayjs from 'dayjs'
import { statuses } from './Statuses'
import { dateFormat } from '~/utils'

export const normalizeToTableReferral = (serverReferral) => {
  return {
    referralCode: serverReferral.referral_code,
    name: serverReferral.title,
    status: statuses.find((status) => status.value === serverReferral.status),
    dates: `${dayjs(serverReferral.created_at).format(dateFormat)} - ${dayjs(
      serverReferral.expires_at
    ).format(dateFormat)}`,
    reg_count: serverReferral.reg_count,
    jump_count: serverReferral.jump_count,
  }
}
