import { events } from './events'

export default function (ctx, inject) {
  const notify = {
    show(params) {
      let resolveFn = () => ({})

      const resolvePromise = new Promise((resolve) => {
        resolveFn = resolve
      })

      events.$emit('show', { resolver: resolveFn, params })

      return resolvePromise
    },
  }
  inject('confirm', notify)
}
