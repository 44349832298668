import * as dayjs from 'dayjs'
import { normalizeUser } from '@/utils/normalize-helpers'
import { formatThousandSeparator } from '@/utils'

export const normalizeToTableCustomer = (serverCustomer) => {
  return {
    id: serverCustomer.uuid,
    ...normalizeUser(serverCustomer),
    // name: `${serverCustomer.personal.first_name} ${serverCustomer.personal.last_name}`,
    // img: require('@/assets/images/empty-avatart.png'),
    // phone: '+' + serverCustomer.contact.phone,
    order: {
      count: serverCustomer.order?.orders_count || '-',
      avgCheque: serverCustomer.order?.avg_cheque
        ? formatThousandSeparator(serverCustomer.order?.avg_cheque) + '₽'
        : '-',
    },
    card: {
      number: serverCustomer.card.number,
    },
    registrationDate: dayjs(serverCustomer.registration_date * 1000).format(
      'D MMM YYYY'
    ),
  }
  // const { header } = serverOrder
  // return {
  // }
}
