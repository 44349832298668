import * as dayjs from 'dayjs'

export const normalizeToSingleCollenction = (serverDocumentCollection) => {
  const res = {
    // ...generalNormalizer(serverDocumentCollection),
    ...serverDocumentCollection,
    createdAt: dayjs(serverDocumentCollection.created_at * 1000).format(
      'DD MMM YYYY'
    ),

    // id: serverEmployee.uuid,
  }

  if (res.type === 'dir') {
    return { ...res, children: [] }
  }
  return res
}
