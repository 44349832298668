//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import AppMenuItem from './AppMenuItem.vue'
import AppMenuItemGroup from './AppMenuItemGroup.vue'

export default {
  components: {
    AppMenuItem,
    AppMenuItemGroup,
  },
  props: {
    isExpanded: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapState(['APP_MENU']),
  },
}
