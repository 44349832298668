import dayjs from 'dayjs'

function initialState() {
  return {
    date: [
      dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
      dayjs().add(1, 'day').format('YYYY-MM-DD'),
    ],
  }
}
export const namespaced = true

export const state = () => {
  return initialState()
}
export const mutations = {
  SET_DATE(state, value) {
    state.date = value
  },
}
export const actions = {}
export const getters = {}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
}
