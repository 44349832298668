import dayjs from 'dayjs'

export const normalizeMonthList = (list = []) => {
  const months = []
  list.forEach((item) => {
    months.push({
      value: item.start_dt,
      label: dayjs(item.start_dt).format('MMMM YYYY'),
    })
  })
  return months
}
