export default function ({ $config }, inject) {
  let mapLoaded = false
  let mapWaiting = null
  const initMap = () => {
    mapLoaded = true
    if (mapWaiting) {
      renderMap(mapWaiting)
      mapWaiting = null
    }
  }

  const addScript = () => {}

  const renderMap = ({ canvas, location }) => {
    // eslint-disable-next-line no-undef
    return new ymaps.Map(canvas, {
      center: [location.lat, location.lng],
      zoom: 18,
    })
  }

  const showMap = (params) => {
    if (mapLoaded) renderMap(params)
    else mapWaiting = params
  }

  const geocodeAddress = (address) => {
    return new Promise((resolve, reject) => {
      if (!window.ymaps) {
        initScript()
          .then(() => {
            geocodeAddress(address).then(resolve).catch(reject)
          })
          .catch(reject)
        return
      }
      window.ymaps
        .geocode(address)
        .then((res) => {
          const coordinates = res.geoObjects.get(0).geometry.getCoordinates()
          resolve(coordinates)
        })
        .catch(reject)
    })
  }
  // const coords = await this.$maps.geocodeAddress('Томск, проспект Фрунзе 25')

  const autocompleteAddress = (query) => {
    return new Promise((resolve, reject) => {
      if (!window.ymaps) {
        initScript()
          .then(() => {
            autocompleteAddress(query).then(resolve).catch(reject)
          })
          .catch(reject)
        return
      }
      // FIXME не работает
      // eslint-disable-next-line no-undef
      const suggestView = window.ymaps.SuggestView('suggest', {
        provider: 'yandex#search',
      })

      suggestView.events.add('resultselect', (event) => {
        const suggestion = event.get('item')
        resolve(suggestion)
      })

      suggestView.events.add('error', (event) => {
        reject(event.get('error'))
      })

      suggestView.suggest(query)
    })
  }

  const initScript = () => {
    return new Promise((resolve, reject) => {
      if (mapLoaded) return resolve()
      const script = document.createElement('script')
      script.src = `https://api-maps.yandex.ru/2.1/?apikey=${$config.YA_MAP_KEY}&lang=ru_RU&onload=initMap`
      script.async = true
      window.initMap = () => {
        resolve()
      }
      document.head.appendChild(script)
      mapLoaded = true
    })
  }

  inject('maps', {
    renderMap,
    initScript,
    geocodeAddress,
    autocompleteAddress,
  })
}
