/* eslint-disable import/order */
import Vue from 'vue'
import merge from '~lodash.mergewith'
import * as Sentry from '~@sentry/vue'

import { ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export default async function (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    Vue,
    release:undefined,
    dsn:"https:\u002F\u002F2eda453047784cc4901a1ca6551ec20d@o4504813348257792.ingest.sentry.io\u002F4504813350486016",
    environment:"production",
  }

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver(),
    new RewriteFrames(),
  ]

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config)

  inject('sentry', Sentry)
  ctx.$sentry = Sentry
}
