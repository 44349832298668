//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'BaseModal',
  inheritAttrs: false,
  props: {
    header: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: Number,
      default: 1200,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    stickyFooter: {
      type: Boolean,
      default: false,
    },
    footerBackground: {
      type: String,
      default: '#fff',
    },
  },
  setup(_, { emit }) {
    const modalRef = ref()
    const closeModal = () => {
      modalRef.value.onClickOutside()
      emit('input', false)
    }

    return { closeModal, modalRef }
  },
  watch: {
    '$attrs.value'() {
      this.$attrs.value ? this.$emit('open-modal') : this.$emit('close-modal')
    },
  },
})
