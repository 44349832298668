//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SheetHeader from './BottomSheet/SheetHeader.vue'
export default {
  components: {
    SheetHeader,
  },
  inheritAttrs: false,
  props: {
    header: {
      type: String,
      default: '',
    },
  },
}
