import { normalizeUser } from '~/utils/normalize-helpers'
import { convertDate } from '~/utils'

export const normalizeToSingleGcCustomer = (serverCustomer) => {
  return {
    id: serverCustomer.uuid,
    ...normalizeUser(serverCustomer),
    stats: {
      ordersCnt: serverCustomer.orders_cnt || 0,
      grade: serverCustomer.grade || 0,
    },
    bonuses: {
      compliments: serverCustomer.bonuses.compliments || 0,
      chips: serverCustomer.bonuses.chips || 0,
    },
    cards: serverCustomer.loyalty_cards || [],
    ctxCard: serverCustomer.loyalty_cards?.[0] || null,
    registrationDate: convertDate(serverCustomer.created_at, 'D MMM YYYY'),
  }
}
