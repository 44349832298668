//
//
//
//
//
//
//
//
//
//
//
//
//
//

import iconsPath from '@/assets/svg/sprite.svg'

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 28,
    },
  },
  computed: {
    spriteHref() {
      return `${iconsPath}#${this.name}`
    },
  },
}
