import Vue from 'vue'

function initialState() {
  return {
    // items: [],

    pageStatsCardsGroups: {},
    SALES_STATS_LOADING: false,
  }
}
export const namespaced = true

export const state = () => {
  return initialState()
}
export const mutations = {
  SET_SALES_STATS_ITEMS(state, { group, cards }) {
    Vue.set(state.pageStatsCardsGroups, group, cards)
  },
  SET_SALES_STATS_LOADING(state, value) {
    state.SALES_STATS_LOADING = value
  },
}
export const actions = {}
export const getters = {
  // pageStatsCardsGroups
  STATS_CARDS(state) {
    let res = []

    for (const [group, cards] of Object.entries(state.pageStatsCardsGroups)) {
      if (group === 'sales-quantity-parts-by-classifiers') continue
      res = res.concat(cards)
    }

    return res
  },
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
}
