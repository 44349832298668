import { normalizeToTableAccounting } from './TableAccounting'

export const normalizeToSingleAccounting = (serverEmployee) => {
  return {
    ...serverEmployee,
    ...normalizeToTableAccounting(serverEmployee),
    // ...userGenderBirthDate(serverEmployee),
    // gettingStarted: getGettingStarted(serverEmployee),
    // isActive: serverEmployee.stat.is_active ? 'Активнй' : 'Не активный',
  }

  // const { header } = serverOrder
  // return {
  // }
}
