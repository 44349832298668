import { render, staticRenderFns } from "./error.vue?vue&type=template&id=551f3e7d&scoped=true&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"
import style0 from "./error.vue?vue&type=style&index=0&id=551f3e7d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "551f3e7d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RostBtn: require('/home/ilya/app/rost_web_frontend/components/rost/btn.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
installComponents(component, {VApp})
