import * as dayjs from 'dayjs'
import { getProductImg } from '@/utils/normalize-helpers'

export const normalizeToSingleСampaign = (serverCampaign) => {
  return {
    ...serverCampaign,
    products:
      serverCampaign.products?.map((el) => ({
        ...el,
        // discount: el.discount ? Math.trunc(el.discount * 100) : '',
        img: getProductImg(el.img),
        // price: el.price ? Number(el.price).toFixed(2) : '-',
        personal_price: el.personal_price
          ? Number(el.personal_price).toFixed(2)
          : '-',
      })) || [],
    date: `${dayjs(serverCampaign.effective_date).format('DD.MM.YYYY')} -
    ${dayjs(serverCampaign.expiration_date).format('DD.MM.YYYY')}`,
  }
}
