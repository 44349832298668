import ruLang from 'vuetify/lib/locale/ru'
import '@mdi/font/css/materialdesignicons.css'
import themes from '../../themes.config'
import colors from '~/utils/colors'
export default function ({ app, $config }) {
  const themeName = $config.theme
  const themeConfig = themes[themeName]

  return {
    lang: {
      locales: { ruLang },
      current: 'ruLang',
    },
    icons: { iconFont: 'mdi' },
    theme: {
      dark: false,
      options: {
        customProperties: true,
        variations: false,
      },
      themes: {
        light: {
          primary: themeConfig.primaryColor,
          'primary-light': themeConfig.primaryLightColor,

          black: '#303033',
          'greyish-black': '#313131',
          'black-dark': '#424351',
          'darker-grey': '#434343',
          grey: '#9AA0B3',
          'less-dark': '#9B9DA5',
          greyish: '#E8ECF4',
          'greyish-less-dark': '#9AA0B3',
          'grey-lighter': '#F8FAFB',
          'grey-light': '#F2F6FD',
          'grey-dark': '#68696F',
          'grey-darken': '#434343',
          'grey-1': '#EDF1F9',
          'grey-2': '#474757',

          green: '#39C3A2',
          'green-600': '#39C3A2',
          'green-500': '#4BD3B3',
          'green-400': '#64D2B7',
          'green-300': '#90DFCC',
          'green-light': '#E8FAF0',

          red: '#E8505B',
          'red-light': '#F8F0F1',

          white: '#fff',

          blue: '#3C7EF5',
          'blue-light': '#DDECFF',

          orange: '#FD9C43',
          'orange-light': '#FFF6ED',

          violet: '#5050EF',

          pink: '#FF6AA0',
          ...colors,
          // accent: colors.grey.darken3,
          // secondary: colors.amber.darken3,
          // info: colors.teal.lighten1,
          // warning: colors.amber.base,

          // success: colors.green.accent3,
          // 'primary-main': '#18898D',
          // 'primary-dark': '#126A6D',
          // 'primary-light': '#1EAAAE',
          // 'primary-opacity-50': '#8BC4C6',
          // 'primary-opacity-10': '#E8F3F4',
          // 'primary-opacity-5': '#F3F9F9',

          // 'dark-main': '#1B1D21',
          // 'dark-grey-1': '#808191',
          // 'dark-grey-2': '#F0F3F6',
          // 'dark-grey-3': '#f7fafc',
          // 'dark-grey-4': 'F1F1F1',
          // 'dark-opacity-20': '#1B1D21',
          // 'dark-opacity-50': '#8D8E90',
          // // 'dark-grey-50': 'rgba(27, 29, 33, 0.5)',
          // 'dark-opacity-70': '#5F6164',
          // 'dark-white': '#FFFFFF',

          // 'accent-01-main': '#3454BE',
          // 'accent-01-dark': '#19348D',
          // 'accent-01-light': '#5579EF',
          // 'accent-01-opacity-50': '#3454BE',

          // 'accent-02-main': '#FFCE73',
          // 'accent-02-light': '#FF9A7B',
          // 'accent-02-dark': '#EE6060',
          // 'accent-02-light-20': '#FFEBE5',
        },
      },
    },
  }
}
