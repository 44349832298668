var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isExpanded)?_c('v-menu',{attrs:{"open-on-hover":true,"offset-x":"","right":"","nudge-right":15,"nudge-top":8,"close-delay":200},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"mb-4",class:{
          'global-in-dev': _vm.item.inDev,
        },attrs:{"ripple":false}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('rost-icon',{attrs:{"name":_vm.item.icon,"size":16}})],1)],1)]}}],null,false,2105217774)},[_vm._v(" "),_c('v-sheet',{staticClass:"px-2 py-1"},_vm._l((_vm.item.children),function(menuItem){return _c('v-list-item',{key:menuItem.id,class:{
          'global-in-dev': menuItem.inDev,
        },attrs:{"active-class":"app-menu-active-class","to":menuItem.to,"link":""}},[_c('v-list-item-content',{staticClass:"rost-14-500"},[_vm._v("\n          "+_vm._s(menuItem.text)+"\n        ")])],1)}),1)],1):_c('v-list-group',{class:{
      'mb-4': !_vm.isExpanded,
    },scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('rost-icon',{attrs:{"name":_vm.item.icon,"size":16}})],1),_vm._v(" "),_c('div',{staticClass:"app-menu__text rost-14-500"},[_vm._v(_vm._s(_vm.item.text))])]},proxy:true},{key:"appendIcon",fn:function(){return [_c('rost-icon',{staticClass:"greyish-black--text menu-chevron",attrs:{"name":"menu-chevron-down","size":12}})]},proxy:true}])},[_vm._v(" "),_vm._l((_vm.item.children),function(menuItem){return _c('v-list-item',{key:menuItem.id,class:{
        'global-in-dev': menuItem.inDev,
      },attrs:{"active-class":"app-menu-active-class","to":menuItem.toFunction ? null : menuItem.to,"link":""},on:{"click":function($event){menuItem.toFunction ? _vm.$router.push(menuItem.toFunction()) : function () {}}}},[_c('div',{staticClass:"app-menu__bullet"},[_vm._v("•")]),_vm._v(" "),_c('v-list-item-content',{staticClass:"app-menu__text rost-14-500"},[_vm._v("\n        "+_vm._s(menuItem.text)+"\n      ")])],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }