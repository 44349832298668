function initialState() {
  return {
    items: [],
  }
}
export const namespaced = true

export const state = () => {
  return initialState()
}
export const mutations = {
  ADD_ITEMS(state, value) {
    state.items = state.items.concat(value)
  },
  ADD_ITEM(state, value) {
    state.items.unshift(value)
  },
  UPDATE_ITEM(state, order) {
    const idx = state.items.findIndex((el) => el.id === order.id)
    state.items.splice(idx, 1, order)
  },
  SET_ITEMS(state, value) {
    state.items = value
  },
}
export const actions = {}
export const getters = {}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
}
