function initialState() {
  return {
    items: [],
    filters: [],
  }
}
export const namespaced = true

export const state = () => {
  return initialState()
}
export const mutations = {
  ADD_ITEMS(state, value) {
    state.items = state.items.concat(value)
  },
  SET_ITEMS(state, value) {
    state.items = value
  },
  setFilters(state, filters) {
    state.filters = filters
  },
  clearFilters(state) {
    state.filters = []
  },
}
export const actions = {
  ADD_ITEMS(context, value) {
    context.commit('ADD_ITEMS', value)
  },
  saveFilters({ commit }, filters) {
    commit('setFilters', filters)
    localStorage.setItem('orderFilters', JSON.stringify(filters))
  },
  loadFilters({ commit }) {
    try {
      const filters = localStorage.getItem('orderFilters')
      if (filters) {
        const parsedFilters = JSON.parse(filters)
        commit('setFilters', parsedFilters)
      }
    } catch (e) {
      console.error('Ошибка парсинга фильтров из localStorage:', e.message)
      localStorage.removeItem('orderFilters')
    }
  },
  clearFilters({ commit }) {
    commit('clearFilters')
    localStorage.removeItem('orderFilters')
  },
}
export const getters = {}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
}
