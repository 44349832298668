//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { useRouter } from '@nuxtjs/composition-api'
import { normalizeUser } from '@/utils/normalize-helpers'
import AppChangeRoleModal from '@/layouts/components/AppChangeRoleModal.vue'

export default {
  components: {
    AppChangeRoleModal,
  },
  setup() {
    const $router = useRouter()
    const onUserClick = (item) => {
      $router.push({ name: 'customer', params: { id: item } })
    }
    return {
      onUserClick,
    }
  },

  computed: {
    user() {
      return normalizeUser(this.$auth.user)
    },
    dispayedCurrentRole() {
      const el = this.$auth.user.roles.find((el) => el.is_selected) ||
        this.$auth.user.roles[0] || { code: 'user', name: 'Пользователь' }
      return el ? el.name : '-'
    },
  },
  methods: {
    async onLogOut() {
      await this.$auth.logout()
    },
  },
}
