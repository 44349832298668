import * as dayjs from 'dayjs'

export const normalizeToSingleWriteOffRequest = (serverRequest) => {
  return {
    ...serverRequest,
    amount: 5,
    for_date: dayjs(serverRequest.for_date).format('D MMM YYYY'),
    // id: serverBonusRequest.id,
    // specializationLabel: employee.specialization.label,
    // createdAt: dayjs(serverBonusRequest.created_at).format('D MMM YYYY'),
    // writeoffAt: dayjs(serverBonusRequest.writeoff_at).format('D MMM YYYY'),

    // status: {
    //   label: serverRequest.status,
    //   value: serverRequest.status,
    //   ...getStatusUI(serverRequest.status),
    // },

    // new, accepted, rejected

    // img: require('@/assets/images/empty-avatart.png'),
  }
}
