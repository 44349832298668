import { getStatusUI } from '../helpers/getStatusUI'
import { normalizeUser } from '@/utils/normalize-helpers'
const getTime = (time) => {
  const [hours, minutes] = time.tm.split(':')
  return `${hours}:${minutes}`
}
const getDate = (time) => {
  return time.dt.split('-').reverse().join('.')
}

const getWeight = (traits) => {
  return (traits?.weight || 0) + 'кг'
}
export const normalizeToTableOrder = (serverOrder) => {
  const distanceValue = serverOrder.order_info?.delivery?.distance

  const distance = distanceValue
    ? (distanceValue / 1000).toFixed(2) + ' км'
    : '-'
  return {
    id: serverOrder.id,
    extra: {
      priority: serverOrder.extra?.priority,
    },
    store: {
      name: serverOrder.store.name,
      icon: serverOrder.store.format.icon && serverOrder.store.format.icon.png,
      // return this.store.warehouse?.address || '-'
      address: serverOrder.store.warehouse.address,
      location: serverOrder.store.warehouse.location,
    },
    client: serverOrder.client ? normalizeUser(serverOrder.client) : null,

    delivery: {
      /*
      time() {
      if (this.item.order_info.time)
        return [this.item.order_info.time.tm, this.item.order_info.time.dt]
      return ['', '']
    },

      */
      time: serverOrder.order_info.time && getTime(serverOrder.order_info.time),
      date: serverOrder.order_info.time && getDate(serverOrder.order_info.time),
      address: serverOrder.order_info?.delivery?.address?.full || '-',
      distance, // '1.5 км',
      location: serverOrder.order_info?.delivery?.address?.location,
      porch: serverOrder.order_info?.delivery?.address?.porch || '-',
      flat: serverOrder.order_info?.delivery?.address?.flat || '-',
      floor: serverOrder.order_info?.delivery?.address?.floor || '-',
      code: serverOrder.order_info?.delivery?.address?.code || '-',

      /*
      address() {
      return this.item.order_info.delivery?.address?.full || '-'
    },
    distance() {
      return this.item.order_info.delivery?.distance || '-'
    },

    */
    },
    total: {
      plan: serverOrder.total.plan,
      planNormalized:
        serverOrder.total.plan.amount + serverOrder.total.plan.currency,
      planDiscount:
        serverOrder.total.plan.amount_discount +
        ' ' +
        serverOrder.total.plan.currency,
      fact: serverOrder.total.fact,
    },
    state: {
      label: serverOrder.state.label,
      ...getStatusUI(serverOrder.state.value),
      value: serverOrder.state.value,
    },
    traits: {
      /*
      lineCount() {
      return this.item.traits.line_count || '-'
    },
    weight() {
      if (this.item.traits.weight) return `${this.item.traits.weight} кг`
      return '-'
    },

      */
      count: serverOrder.traits?.line_count || '-',
      weight: serverOrder.traits ? getWeight(serverOrder.traits) : '-',
      countWeightFormated: serverOrder.traits
        ? serverOrder.traits.line_count + ` (${getWeight(serverOrder.traits)})`
        : '-',
    },
  }
}
