import dayjs from 'dayjs'

export const normalizeToSingleEmployee = (serverEmployee) => {
  const staticUrl = process.env.baseURLStatic
  const qualificationColors = {
    trainee: 'pink--text',
    newbie: 'green--text',
    experienced: 'blue--text',
    pro: 'primary--text',
  }
  return {
    uuid: serverEmployee.uuid,
    hire_date: dayjs(serverEmployee.hire_date * 1000).format('YYYY-MM-DD'),
    tips_url: serverEmployee.documents.tips.tips_url,
    personal: {
      ...serverEmployee.personal,
      name: `${serverEmployee.personal.last_name} ${serverEmployee.personal.first_name}`,
      img: serverEmployee.personal.img?.path
        ? `${staticUrl}/resize/512x512/${serverEmployee.personal.img.path}`
        : require('@/assets/images/empty-avatart.png'),
      birth_date: serverEmployee.personal.birth_date,
    },
    contact: {
      ...serverEmployee.contact,
      phone: serverEmployee.contact.phone ?? '-',
      eMail: serverEmployee.contact.eMail ?? '-',
    },
    statuses: {
      is_active: serverEmployee.statuses?.is_active || false,
      is_taxpayer: serverEmployee.statuses?.is_taxpayer || false,
    },
    role: serverEmployee.role,
    qualification: {
      ...serverEmployee.qualification,
      color: qualificationColors[serverEmployee.qualification.value],
    },
    medbook: serverEmployee.documents.medbook && {
      ...serverEmployee.documents.medbook,
      expiration_date: dayjs(
        serverEmployee.documents.medbook.expiration_date
      ).format('YYYY-MM-DD'),
    },
    stats: serverEmployee.stats && {
      ...serverEmployee.stats,
      rating: serverEmployee.stats.rating || '-',
      kpi: serverEmployee.stats.kpi,
      order_cnt: serverEmployee.stats.order_cnt || '-',
      fix_salary: serverEmployee.stats.fix_salary,
    },
    documents: serverEmployee.documents,
  }
}
