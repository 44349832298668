function initialState() {
  return {
    REQUEST_STATUSES: {
      claimStatus: {},
      claimLineStatus: {},
    },
  }
}
export const namespaced = true

export const state = () => {
  return initialState()
}
export const mutations = {
  SET_REQUEST_STATUSES(state, value) {
    state.REQUEST_STATUSES = value
  },
}
export const actions = {
  async LOAD_REQUEST_STATUSES({ commit, state }) {
    if (Object.keys(state.REQUEST_STATUSES.claimStatus).length) return
    const res =
      await this.$systemEmployeesRepository.raiseAndBonusTransactionsStatuses()
    commit('SET_REQUEST_STATUSES', res.data)
  },
}
export const getters = {
  RAISE_REQUEST_STATUSES_LIST(state) {
    const availableStatuses = []
    for (const [key, value] of Object.entries(
      state.REQUEST_STATUSES.claimLineStatus
    )) {
      availableStatuses.push({ id: key, name: value })
    }
    return availableStatuses
  },
  RAISE_REQUEST_STATUSES_DICTIONARY(state) {
    return state.REQUEST_STATUSES.claimLineStatus
  },

  BONUS_REQUEST_STATUSES_LIST(state) {
    const availableStatuses = []
    for (const [key, value] of Object.entries(
      state.REQUEST_STATUSES.claimStatus
    )) {
      availableStatuses.push({ id: key, name: value })
    }
    return availableStatuses
  },
  BONUS_REQUEST_STATUSES_DICTIONARY(state) {
    return state.REQUEST_STATUSES.claimStatus
  },

  BONUS_SETABLE_REQUEST_STATUSES_LIST(state, getters) {
    return getters.RAISE_REQUEST_STATUSES_LIST.filter((el) => el.id !== 'new')
  },
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
}
