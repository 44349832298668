import { render, staticRenderFns } from "./AppScrollToTopBtn.vue?vue&type=template&id=38ebb10e&"
import script from "./AppScrollToTopBtn.vue?vue&type=script&lang=js&"
export * from "./AppScrollToTopBtn.vue?vue&type=script&lang=js&"
import style0 from "./AppScrollToTopBtn.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RostIcon: require('/home/ilya/app/rost_web_frontend/components/rost/icon.vue').default})
