//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  inheritAttrs: false,
  props: {
    textColor: {
      type: String,
      default: 'dark-white',
    },
    size: {
      type: Number,
      default: 0,
    },
    small: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    fontWeight: {
      type: Number,
      default: 500,
    },
    rostIcon: {
      type: String,
      default: null,
    },
  },
  computed: {
    isNeedFixHover() {
      return this.$attrs.color === 'green-light'
    },
    vutifyTextColor() {
      return this.$vuetify.theme.themes.light[this.textColor]
    },
    vutifyUnderlineRowColor() {
      return this.$vuetify.theme.themes.light[this.$attrs.color]
    },
    sizeAttrs() {
      if (this.size)
        return {
          'min-width': this.size,
          height: this.size,
          width: this.size,
          class: 'pa-0',
        }
      return {}
    },
    bindedAttrs() {
      return {
        ...this.sizeAttrs,
        ...this.$attrs,
      }
    },
  },
}
