import * as dayjs from 'dayjs'
import { getTime } from '@/utils/normalize-helpers'

export const normalizeToTableNotification = (serverNotification) => {
  const stateToChip = {
    draft: {
      color: '#FFFFFF',
      textColor: '#797F95',
      borderColor: '#E8ECF4',
    },
    done: { color: '#EDF1F9', textColor: '#9AA0B3', borderColor: '#E7ECF4' },
    planned: { color: '#EDFEFF', textColor: '#3AAEBE', borderColor: '#E4F9FA' },
    cancelled: {
      color: '#EDF1F9',
      textColor: '#9AA0B3',
      borderColor: '#E7ECF4',
    },
    unknown: { color: '#EDF1F9', textColor: '#9AA0B3', borderColor: '#E7ECF4' },
  }
  return {
    ...serverNotification,
    date: dayjs(serverNotification.date).format('DD.MM.YYYY'),
    time: `${getTime(serverNotification.start_tm)} - ${getTime(
      serverNotification.end_tm
    )}`,
    stateChipsUi: stateToChip[serverNotification.state.code],
  }
}
