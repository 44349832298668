function initialState() {
  return {
    items: [],
    SALARY_DOCUMNETS_TRANSITIONS: null,
    SALARY_DOCUMNETS_STATES_DICTIONARY: null,
    SALARY_DOCUMNETS_STATES_LIST: null,
  }
}
export const namespaced = true

export const state = () => {
  return initialState()
}
export const mutations = {
  ADD_ITEMS(state, value) {
    state.items = state.items.concat(value)
  },
  ADD_ITEM(state, value) {
    state.items.unshift(value)
  },
  UPDATE_ITEM(state, order) {
    const idx = state.items.findIndex((el) => el.id === order.id)
    state.items.splice(idx, 1, order)
  },
  SET_ITEMS(state, value) {
    state.items = value
  },
  SET_SALARY_DOCUMNETS_STATES(state, value) {
    const statesDictionary = {}
    const transitionDictionary = {}
    value.states.forEach((state) => {
      statesDictionary[state.value] = state
    })

    value.transitions.forEach((transition) => {
      const toList = transitionDictionary[transition.from]
      const newTo = statesDictionary[transition.to]
      if (toList) transitionDictionary[transition.from].push(newTo)
      else transitionDictionary[transition.from] = [newTo]
    })

    state.SALARY_DOCUMNETS_TRANSITIONS = transitionDictionary
    state.SALARY_DOCUMNETS_STATES_DICTIONARY = statesDictionary
    state.SALARY_DOCUMNETS_STATES_LIST = value.states
  },
}
export const actions = {}
export const getters = {}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
}
