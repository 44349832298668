import dayjs from 'dayjs'

export const shortenAddress = (address) => {
  const words = address.split(',').map((word) => word.trim())
  const shortenedWords = words.slice(-3)
  return shortenedWords.join(', ')
}

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export const ruDateFormat = 'DD.MM.YYYY'
export const yearMonthDayFormat = 'YYYY-MM-DD'

export const convertDate = (date, format = ruDateFormat) => {
  if (typeof date === 'number') {
    return dayjs.unix(date).format(format)
  }
  return dayjs(date).format(format)
}

export const isPhoneValid = (phone) => {
  if (!phone) return true
  const clearPhone = phone.replace(/[^+\d]/g, '')
  return clearPhone.length > 8
}

export const dateFormat = 'DD.MM.YYYY'

export const normalizePhone = (phone) => {
  return '+7' + phone.replace(/[^+\d]/g, '')
}
export const debounce = function (
  func,
  waitMilliseconds = 50,
  options = {
    isImmediate: false,
  }
) {
  let timeoutId

  const debouncedFunction = function (...args) {
    const context = this

    const doLater = function () {
      timeoutId = undefined
      if (!options.isImmediate) {
        func.apply(context, args)
      }
    }

    const shouldCallNow = options.isImmediate && timeoutId === undefined

    if (timeoutId !== undefined) {
      clearTimeout(timeoutId)
    }

    timeoutId = setTimeout(doLater, waitMilliseconds)

    if (shouldCallNow) {
      func.apply(context, args)
    }
  }

  debouncedFunction.cancel = function () {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId)
    }
  }

  return debouncedFunction
}

export const filterWordNumForm = (n, wordForms, returnOnlyWord) => {
  n = Math.abs(n) % 100
  const n1 = n % 10
  if (wordForms) {
    if (n > 10 && n < 20) {
      return !returnOnlyWord ? n + ' ' + wordForms[2] : wordForms[2]
    }
    if (n1 > 1 && n1 < 5) {
      return !returnOnlyWord ? n + ' ' + wordForms[1] : wordForms[1]
    }
    if (n1 === 1) {
      return !returnOnlyWord ? n + ' ' + wordForms[0] : wordForms[0]
    }
    return !returnOnlyWord ? n + ' ' + wordForms[2] : wordForms[2]
  } else {
    return null
  }
}

export const makeNotificationSound = (volume) => {
  return new Promise((resolve, reject) => {
    volume = volume || 1.0

    try {
      const soundSource = '/notification-sound.mp3'
      const sound = new Audio(soundSource)
      sound.volume = volume
      sound.onended = () => {
        resolve()
      }
      sound.play().catch((err) => {
        reject(err)
      })
    } catch (error) {
      reject(error)
    }
  })
}

export const formatThousandSeparator = (a) => {
  const roundedNumber = Number.isInteger(a) ? a : Number(a).toFixed(1)
  return roundedNumber
    .toString()
    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ' ')
}

export const createFormDataImage = (img, location) => {
  const file2Upload = new FormData()
  file2Upload.append('location', location)
  file2Upload.append('img', img)
  return file2Upload
}

export const createFormDataVideo = (video, location) => {
  const file2Upload = new FormData()
  file2Upload.append('location', location)
  file2Upload.append('content', video)
  return file2Upload
}
