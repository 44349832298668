const getAppInitialState = ($axios) => {
  const getSallaryDocumentsStates = async () => {
    try {
      const res = await $axios.$get(
        `/system/accounting/salary_documents/states/`
      )
      return res
    } catch (error) {
      if (error.isAxiosError && error.response.status === 403) {
        return false
      }
      throw new Error(error)
    }
  }
  return Promise.all([
    $axios.$get(`/system/users/capabilities/`),
    getSallaryDocumentsStates(),
    $axios.$get(`/sys/erp/v1/user/allowed-partners/`),
  ])
}

const setPartnersLogic = (availablePartnersRes, dependencies) => {
  const { store } = dependencies
  const userAllowedPartners = availablePartnersRes.data.items
  store.commit('SET_APP_USER_PARTNERS', userAllowedPartners)

  if (userAllowedPartners.length) {
    const LSSelectedPartnerId = localStorage.getItem('APP_SELECTED_PARTNER_ID')
    // TODO
    // 2. очистка стореджа при разлогине
    let selectedPartner =
      LSSelectedPartnerId === null
        ? userAllowedPartners[0]
        : userAllowedPartners.find((el) => el.id === +LSSelectedPartnerId)

    // если партнер из локал стореджа не нашелся в доступных
    if (!selectedPartner) selectedPartner = userAllowedPartners[0]

    store.commit('SET_APP_SELECTED_PARTNER', selectedPartner)
  }
}

export default async ({ app }, inject) => {
  const { $axios, store, $toast } = app
  const initFunction = async () => {
    try {
      const [appMenuRes, salaryDocumentsStatesRes, availablePartnersRes] =
        await getAppInitialState($axios)
      const capabilities = appMenuRes.data.capabilities
      if (salaryDocumentsStatesRes) {
        store.commit(
          'accounting/SET_SALARY_DOCUMNETS_STATES',
          salaryDocumentsStatesRes.data
        )
      }
      store.commit('SET_APP_MENU', capabilities)

      setPartnersLogic(availablePartnersRes, {
        store,
      })
    } catch (error) {
      if (error.isAxiosError && error.response.status === 401) {
        return
      }
      $toast.global.reqError(
        `Произошла Ошибка ${error.message}. Перезагрузите страницу`
      )
    }
  }

  await initFunction()

  inject('initApp', initFunction)
  inject('appGlobal', {
    selectedPartnerId:
      store.state.APP_SELECTED_PARTNER && store.state.APP_SELECTED_PARTNER.id,
  })
}
