export const statuses = [
  {
    value: 'active',
    label: 'Активный',
  },
  {
    value: 'inactive',
    label: 'Неактивный',
  },
]
