export default {
  default: {
    primaryColor: '#5F5FF6',
    primaryLightColor: '#F2F1FF',
    menuLogoCompact: require('./assets/svg/illustrations/themes/default/logo-img.svg'),
    menuLogoExpended: require('./assets/svg/illustrations/themes/default/logo-word.svg'),
  },
  gc: {
    primaryColor: '#33C66E',
    primaryLightColor: '#E3F8F3',
    menuLogoCompact: require('./assets/svg/illustrations/themes/gc/logo-img.svg'),
    menuLogoExpended: require('./assets/svg/illustrations/themes/gc/logo-word.svg'),
  },
}
