import { initializeApp, getApps } from 'firebase/app'
import { isSupported } from 'firebase/analytics'
import { getMessaging } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyDxq-GeO--_7ZyJmTTxTMxxuztiPcggBJI',
  authDomain: 'bviolet-474a7.firebaseapp.com',
  databaseURL: 'https://bviolet-474a7.firebaseio.com',
  projectId: 'bviolet-474a7',
  storageBucket: 'bviolet-474a7.appspot.com',
  messagingSenderId: '738885193624',
  appId: '1:738885193624:web:cbb89c3cf4c3902866e5ed',
  measurementId: 'G-DX4F3CQTQP',
}

const apps = getApps()

const app = !apps.length ? initializeApp(firebaseConfig) : apps[0]

export const appMessaging = async () => {
  try {
    const isSypportedBrowser = isSupported()
    if (isSypportedBrowser) {
      return await getMessaging(app)
    }
    console.log('Browser not supported Firebase')
    return null
  } catch (e) {
    console.log(e)
    return null
  }
}
