import { computed, ref } from '@nuxtjs/composition-api'

class ValidationError extends Error {
  constructor(message) {
    super(message)
    this.name = 'ValidationError'
  }
}

export function useAppFetch() {
  const error = ref(null)
  const rawError = ref(null)
  const pending = ref(false)

  const makeFetch = async (reqestFn) => {
    error.value = null
    rawError.value = null
    pending.value = true

    let resp = null
    try {
      resp = await reqestFn()
      return resp
    } catch (err) {
      if (process.dev) {
        console.error('Error in fetch():', err)
      }

      let statusCode = 500
      let errorMessage = 'An unexpected error occurred'
      let rawError = err

      if (err.isAxiosError) {
        statusCode = err.response?.status || 500
        errorMessage =
          err.response?.data?.status?.message ||
          err.response?.data ||
          err.message
        rawError = err.response?.data || err
      } else {
        errorMessage = err.message || 'An unexpected error occurred'
      }
      const errorObj = {
        message: errorMessage,
        statusCode,
        rawError,
      }

      throw errorObj
    } finally {
      pending.value = false
    }
  }

  return {
    error,
    rawError,
    pending,
    makeFetch,
  }
}
