import * as dayjs from 'dayjs'
import { getStatusUI } from '../helpers'
import { normalizeUser } from '@/utils/normalize-helpers'

export const normalizeToTableBonusRequests = (serverBonusRequest) => {
  const employee = serverBonusRequest.employee

  /*
  
  {
  "id": 0,
  "employee": {
    "uuid": "string"
  },
  "total": 0,
  "description": "string",
  "status": {
    "value": "new",
    "label": "Новый"
  },
  "created_at": "string",
  "writeoff_at": "string"
}


  */
  return {
    ...serverBonusRequest,
    id: serverBonusRequest.id,
    ...normalizeUser(employee),
    specializationLabel: employee.specialization.label,
    createdAt: dayjs(serverBonusRequest.created_at).format('D MMM YYYY'),
    writeoffAt: dayjs(serverBonusRequest.writeoff_at).format('D MMM YYYY'),
    status: {
      label: serverBonusRequest.status.label,
      value: serverBonusRequest.status.value,
      ...getStatusUI(serverBonusRequest.status.value),
    },

    // new, accepted, rejected

    // img: require('@/assets/images/empty-avatart.png'),
  }
}
