import * as dayjs from 'dayjs'
import { normalizeUser } from '@/utils/normalize-helpers'

export const normalizeToTableGcCustomer = (serverCustomer) => {
  return {
    id: serverCustomer.uuid,
    ...normalizeUser(serverCustomer),
    ordersCnt: serverCustomer.orders_cnt || 0,
    card: {
      number: serverCustomer.card?.number || '-',
    },
    registrationDate: dayjs(serverCustomer.created_at * 1000).format(
      'D MMM YYYY'
    ),
  }
}
