//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    layoutClasses() {
      // const pagesWithWhiteBg = ['module-chips-new', 'module-chips-edit']
      return {
        // 'white-layout': pagesWithWhiteBg.includes(this.$route.name),
      }
    },
  },
}
