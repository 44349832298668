import { getProductImg, normalizeUser } from '~/utils/normalize-helpers'
import { convertDate } from '~/utils'

export const normalizeToTableGcOrder = (serverOrder) => {
  const staticUrl = process.env.baseURLStatic
  const imgPath = serverOrder.customer?.img?.path || null
  return {
    id: serverOrder.id,
    number: serverOrder.id,
    store: serverOrder.store ?? 'Магазин',
    img: serverOrder.img?.path
      ? getProductImg(serverOrder.img)
      : require('@/assets/images/company-logo.svg'),
    indicator: serverOrder.indicator ?? null,
    deliver: {
      ...serverOrder.deliver,
      date: convertDate(serverOrder.deliver.date, 'YYYY-MM-DD'),
      time: serverOrder.deliver.time,
      indicator: serverOrder.indicator ?? null,
    },
    customer: {
      // name: `${serverOrder.customer?.first_name || '-'} ${
      //   serverOrder.customer?.last_name || '-'
      // }`,
      ...normalizeUser({
        personal: serverOrder.customer,
        contact: {
          phone: serverOrder.customer?.identifiers?.phone ?? '-',
        },
      }),
      img: imgPath
        ? `${staticUrl}/resize/128x128/${imgPath}`
        : require('@/assets/images/company-logo.svg'),
      // phone: serverOrder.customer?.phone ?? '-',
    },
    address: {
      name: serverOrder.address.address,
      distance: serverOrder.address.distance
        ? `${(serverOrder.address.distance / 1000).toFixed(2)} км`
        : '0 км',
    },
    status: serverOrder.status,
    rating: serverOrder.rating,
  }
}
