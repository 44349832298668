export const getStatusUI = (status) => {
  /*
[{'status': 'draft', 'name': 'Черновик'},
{'status': 'cancelled_by_admin', 'name': 'Отменен администратором'},
{'status': 'cancelled_auto', 'name': 'Отменен автоматом'},
{'status': 'error', 'name': 'Ошибка'},
{'status': 'new', 'name': 'Новый'},
{'status': 'accepted', 'name': 'Принят'},
{'status': 'collecting', 'name': 'Сборка'},
{'status': 'collected', 'name': 'Собран'},
{'status': 'payed', 'name': 'Оплачен'},
{'status': 'ready_to_delivery', 'name': 'Готов к доставке'},
{'status': 'delivery', 'name': 'Доставляется'},
{'status': 'done', 'name': 'Закрыт'},
{'status': 'cancelled', 'name': 'Отменен пользователем'},
{'status': 'payment', 'name': 'Оплата'},
{'status': 'wait_user_action', 'name': 'Ожидает подтверждения пользователя'},
{'status': 'delivery_picked_up', 'name': 'Курьер забрал заказ'}]
  */
  const statusToUI = {
    draft: {
      color: '№FFFFFF',
      textColor: '#9AA0B3',
      borderColor: '#F4F8FF',
    },
    cancelled_by_admin: {
      color: '#EDF1F9',
      textColor: '#9AA0B3',
      borderColor: '#E7ECF4',
    },
    cancelled_auto: {
      color: '#EDF1F9',
      textColor: '#9AA0B3',
      borderColor: '#E7ECF4',
    },
    error: {
      color: '#F8F0F1',
      textColor: '#FFEEF1',
      borderColor: '#FFEEF1',
    },
    new: {
      color: '#E8FAF0',
      textColor: '#39C3A2',
      borderColor: '#E0F2E8',
    },
    accepted: {
      color: '#E8FAF0',
      textColor: '#39C3A2',
      borderColor: '#E0F2E8',
    },
    collecting: {
      color: '#EDFEFF',
      textColor: '#3AAEBE',
      borderColor: '#E4F9FA',
    },
    collected: {
      color: '#EDFEFF',
      textColor: '#3AAEBE',
      borderColor: '#E4F9FA',
    },
    payed: {
      color: '#FFF6ED',
      textColor: '#FD9C43',
      borderColor: '#FFEEDD',
    },
    ready_to_delivery: {
      color: '#DDECFF',
      textColor: '#3C7EF5',
      borderColor: '#D2E5FC',
    },
    delivery: {
      color: '#DDECFF',
      textColor: '#3C7EF5',
      borderColor: '#D2E5FC',
    },
    done: {
      color: '#EEEDFF',
      textColor: '#5F5FF6',
      borderColor: '#DDDCF0',
    },
    cancelled: {
      color: '#EDF1F9',
      textColor: '#9AA0B3',
      borderColor: '#E7ECF4',
    },
    payment: {
      color: '#FFF6ED',
      textColor: '#FD9C43',
      borderColor: '#FFEEDD',
    },
    wait_user_action: {
      color: '#EEEDFF',
      textColor: '#5F5FF6',
      borderColor: '#DDDCF0',
    },
    delivery_picked_up: {
      color: '#DDECFF',
      textColor: '#3C7EF5',
      borderColor: '#D2E5FC',
    },
  }

  return statusToUI[status]
}
