import qs from 'qs'
export default function (ctx) {
  const { $axios } = ctx

  $axios.onRequest((config) => {
    const { store, $auth } = ctx
    if ($auth.user) {
      config.headers['X-Role'] = $auth.user.scope.role
    }
    config.paramsSerializer = (params) => {
      return qs.stringify(params, { indices: false, skipNulls: true })
    }

    if (store.state.APP_SELECTED_PARTNER)
      config.headers['X-Partner-Id'] = store.state.APP_SELECTED_PARTNER.id

    // qs.stringify({ a: ['b', 'c', 'd'] }, { indices: false });
  })
}
