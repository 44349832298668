import { getProductImg } from '@/utils/normalize-helpers'

export const normalizeToSingleProductGroup = (serverGroup) => {
  return {
    ...serverGroup,
  }
}

export const normalizeToProductsInGroup = (serverProduct) => {
  return {
    name: null,
    gtin: null,
    type: null,
    group: {},
    brand: null,
    ...serverProduct,
    id: serverProduct.product_id,
    image: getProductImg(serverProduct.img),
  }
}
