import * as dayjs from 'dayjs'

export const normalizeToDocumentCollenction = (serverDocumentCollection) => {
  return {
    // ...generalNormalizer(serverDocumentCollection),
    ...serverDocumentCollection,
    updatedAt: dayjs(serverDocumentCollection.updated_at * 1000).format(
      'DD MMM YYYY'
    ),

    // id: serverEmployee.uuid,
  }
}
