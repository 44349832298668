//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {},

  data() {
    return {
      pageYOffset: 0,
      showBtn: false,
      lastScrollTop: 0,
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.pageYOffset = window ? window.pageYOffset : 0
      let scrollTimeout
      const scrollThrottler = () => {
        if (!scrollTimeout) {
          scrollTimeout = setTimeout(() => {
            scrollTimeout = null

            const st = window.pageYOffset || document.documentElement.scrollTop // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
            if (st > this.lastScrollTop) {
              // downscroll code
            } else {
              this.setBtnVisibility()
            }
            this.lastScrollTop = st <= 0 ? 0 : st // For Mobile or negative scrolling
          }, 1000)
        }
      }

      window.addEventListener('scroll', scrollThrottler, { passive: true })

      this.$on('hook:beforeDestroy', () => {
        window.removeEventListener('scroll', scrollThrottler, { passive: true })
      })
    })
  },
  methods: {
    setBtnVisibility() {
      const pageYOffset = window ? window.pageYOffset : 0

      if (pageYOffset > 500) this.showBtn = true
      else this.showBtn = false
    },
  },
}
