//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { debounce } from '@/utils/index'

export default {
  inheritAttrs: false,
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 44,
    },
    color: {
      type: String,
      default: 'white',
    },
    maxWidth: {
      type: Number,
      default: 445,
    },
    backgroundColor: {
      type: String,
      default: 'neutral-25',
    },
    isShowSearchIcon: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    innerAttrs() {
      const attrs = {
        ...this.$attrs,
        height: this.height,
      }
      if (this.$vuetify.breakpoint.smAndDown) {
        attrs.height = 40
      }
      // return {
      //   height: 40,
      // }

      return attrs
    },
  },
  methods: {
    onClear() {
      this.$emit('input', '')
      this.$emit('search')
    },
    onSearchInput: debounce(function () {
      this.$emit('search')
    }, 1200),
  },
}
